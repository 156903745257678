// Function to animate the counter
function animateCounter(counter) {
  const target = +counter.getAttribute("data-target");
  const speed = 150; // Adjust speed of counting
  const increment = target / speed;

  let currentValue = 0;

  const updateCounter = () => {
    if (currentValue < target) {
      currentValue += increment;
      counter.textContent = Math.ceil(currentValue);
      requestAnimationFrame(updateCounter);
    } else {
      counter.textContent = `${formatNumber(target)}+`; // Ensure it ends exactly on the target
    }
  };

  updateCounter();
}

// Format numbers using Australian locale
function formatNumber(number) {
  return new Intl.NumberFormat("en-AU").format(number);
}

const setupAnimateCounter = () => {
  // Observe when the counter is in view
  const counters = document.querySelectorAll(".counter");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateCounter(entry.target);
          observer.unobserve(entry.target); // Stop observing once animation starts
        }
      });
    },
    { threshold: 0.5 } // Trigger when 50% of the element is visible
  );

  counters.forEach((counter) => observer.observe(counter));
};

module.exports = setupAnimateCounter;
